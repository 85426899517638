import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import FormData from 'form-data'
import { useAlert } from 'react-alert'
import { host, url } from '../../config/common';
import axios from 'axios';

const UnlistedServiceUpdateModal = ({ show, handleClose, unlistedServiceData, airportList, refreshData, ...props }) => {
  const [disableButtons, setDisableButtons] = useState(false);
  const initialData = {
    airportID: "",
    fboId: "",
    fboName: "",
    serviceName: "",
    userName: "",
    userEmail: "",
    serviceDetails: ""
  };
  const [modalEventData, setModalEventData] = useState(unlistedServiceData?._id ? { ...unlistedServiceData } : initialData);

  const [validated, setValidated] = useState(false);
  const alert = useAlert()

  const updateUnlistedService = async (e) => {
    e.preventDefault();
    try {
      const form = e.currentTarget;
      console.log("validate", form.checkValidity());
      if (form.checkValidity() === false) {
        e.stopPropagation();
        setValidated(true);
      } else {
        setValidated(true);
        setDisableButtons(true);
        const formData = new FormData();
        formData.append("_id", modalEventData?._id);
        formData.append("fboId", modalEventData?.fboId);
        formData.append("fboName", modalEventData?.fboName);
        formData.append("airportID", modalEventData?.airportID);
        formData.append("serviceName", modalEventData?.serviceName);
        formData.append("userName", modalEventData?.userName ? modalEventData?.userName : "");
        formData.append("userEmail", modalEventData?.userEmail ? modalEventData?.userEmail : "");
        formData.append("serviceDetails", modalEventData?.serviceDetails ? modalEventData?.serviceDetails : "");
        formData.append("status", 1);

        let updateEvent = await axios({
          method: 'post',
          headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
          url: `${host}${url.updateShareAnUpdate}`,
          data: formData
        });
        if (updateEvent) {
          alert.show("Unlisted service updated", { type: 'success' });
          setModalEventData(initialData);
          refreshData();
          setDisableButtons(false);
          handleClose();
        }
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const closeAction = () => {
    if (modalEventData._id) {
      setModalEventData({ ...unlistedServiceData })
      handleClose()
      return
    }
    setModalEventData(initialData);
    setDisableButtons(false);
    handleClose()
  }

  useEffect(() => {
    setModalEventData(unlistedServiceData?._id ? { ...unlistedServiceData } : initialData);
  }, [unlistedServiceData]);

  return (
    <div>
      <Offcanvas backdrop="static" style={{ width: "50%" }} placement="end" show={show} onHide={closeAction} >
        <Offcanvas.Body>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Unlisted Service Request</Offcanvas.Title>
          </Offcanvas.Header>
          <Form noValidate validated={validated} onSubmit={updateUnlistedService}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Airport ID <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type='text' value={modalEventData.airportID} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Service Name <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Service Name" onChange={(e) => setModalEventData({ ...modalEventData, serviceName: e.target.value })} value={modalEventData?.serviceName} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>User Email </Form.Label>
                  <Form.Control type='email' placeholder="User Email"
                    onChange={(e) => setModalEventData({ ...modalEventData, userEmail: e.target.value })}
                    value={modalEventData?.userEmail}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>FBO</Form.Label>
                  <Form.Control type='text' value={modalEventData?.fboName} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>User Name </Form.Label>
                  <Form.Control type='text' placeholder="User Name" onChange={(e) => setModalEventData({ ...modalEventData, userName: e.target.value })} value={modalEventData?.userName} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Service Details </Form.Label>
                  <Form.Control type='text' placeholder="Service Details" onChange={(e) => setModalEventData({ ...modalEventData, serviceDetails: e.target.value })} value={modalEventData?.serviceDetails} />
                </Form.Group>
              </Col>
            </Row>
            <div>
              <Button disabled={disableButtons} variant="outline-secondary" style={{ marginRight: "5px" }} onClick={closeAction}>
                Cancel
              </Button>
              <Button disabled={disableButtons} variant="outline-primary" type='submit'>
                Save
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default UnlistedServiceUpdateModal;
