import React, { useState } from 'react';
import DateRange from '@mui/icons-material/DateRange';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonIcon from '@mui/icons-material/Person';
import LanguageIcon from '@mui/icons-material/Language';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import { host, url } from '../../config/common';
import { useAlert } from 'react-alert';
import UnlistedServiceUpdateModal from './unlistedServiceUpdateModal';
import { Approval } from '@mui/icons-material';

const UnlistedEventCard = ({ unlistedServiceData, refreshData, airportList, ...props }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showFullDescription, setFullDescription] = useState(false);
  const alert = useAlert();

  const description = showFullDescription
    ? unlistedServiceData?.serviceDetails
    : unlistedServiceData?.serviceDetails?.slice(0, 30);

  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  const handleEdit = () => {
    setShowEdit(true)
  }

  const handleClose = () => {
    setShowEdit(false)
  }

  const acceptRejectRequest = async (id, status) => {
    try {
      let eventRequest = await axios({
        method: 'patch',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.changeStatusShareAnUpdate}`,
        data: {
          _id: id,
          status: status
        }
      });
      if (eventRequest) {
        alert.show(`Request ${status === 1 ? "accepted" : "rejected"}`, {type: 'success'})
        setshowDeleteModal(false);
        refreshData()
      }
    } catch (error) {
      console.log({ error });
    }
  }

  return (
    <div>
      <UnlistedServiceUpdateModal show={showEdit} handleClose={handleClose} unlistedServiceData={unlistedServiceData} airportList={airportList} refreshData={() => refreshData()}/>
      <Modal show={showDeleteModal} onHide={() => setshowDeleteModal(false)} backdrop="static">
        <Modal.Body>Are you sure you want to reject the unlisted service request?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setshowDeleteModal(false)}>
            No
          </Button>
          <Button variant="outline-danger" onClick={() => acceptRejectRequest(unlistedServiceData._id, 2)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Card style={{ flexDirection: "initial", marginBottom: "10px" }}>
        <Card.Body style={{ textAlign: "initial" }}>
          <p style={{ fontWeight: "300" }}><LocalAirportIcon fontSize='10px' />  <b>Airport ID :</b> {unlistedServiceData?.airportID}</p>
          <p style={{ fontWeight: "300" }}><Approval fontSize='10px' />  <b>FBO :</b> {unlistedServiceData?.fboName}</p>
          <p style={{ fontWeight: "300" }}><PhoneAndroidIcon fontSize='10px' />  <b>Service Name :</b> {unlistedServiceData?.serviceName}</p>
          <p style={{ fontWeight: "300" }}><LanguageIcon fontSize='10px' />  <b>User Name :</b> {unlistedServiceData?.userName}</p>
          <p style={{ fontWeight: "300" }}><HourglassBottomIcon fontSize='10px' />  <b>User Email :</b> {unlistedServiceData?.userEmail}</p>
          <p style={{ fontWeight: "300" }}><ContactMailIcon fontSize='10px' />  <b>Details :</b> {description} {unlistedServiceData?.serviceDetails?.length > 30 && <span onClick={showFullDescriptionHandler}>
            <b>Read {showFullDescription ? "Less" : "More..."}</b></span>}</p>
          <p style={{ fontWeight: "300" }}><PersonIcon fontSize='10px' />  <b>Requested By :</b> {unlistedServiceData?.requestedBy}</p>
          <p style={{ fontWeight: "300" }}><DateRange fontSize='10px' />  <b>Requested Date :</b> {moment(unlistedServiceData?.createdAt).format("Do MMM YYYY")}</p>
          <Row>
            <Col>
              <div style={{ display: "flex", justifyContent: "end" }}>
                {unlistedServiceData?.status === 0 ?
                  <div>
                    <span style={{ fontWeight: "300", color: "#006ba3", cursor: "pointer", marginRight: "10px" }} onClick={() => handleEdit()}><SwipeRightIcon fontSize='9px' /> Accept</span>
                    <span style={{ fontWeight: "300", color: "red", cursor: "pointer" }} onClick={() => setshowDeleteModal(true)}><DeleteOutlineIcon fontSize='9px' /> Reject</span>
                  </div>
                  :
                  unlistedServiceData?.status === 1 ? <span style={{color: "green"}}>Accepted</span> : <span style={{color: "red"}}>Rejected</span>
                }

              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default UnlistedEventCard;
