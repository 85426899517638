// const host = 'http://localhost:3000'
// staging
// const host = "http://54.146.229.41:3000"
// const host = "https://fboprops.resourcifi.tech/api"
// const host = "https://apifbo.resourcifi.tech/api"
// prod
const host = "https://admin.fboprops.com/api"

// const host = "http://3.238.148.148:4000"

const url = {
  getDashboardData: "/user/getDashboardData",

  userSupport: '/user/verifyOtpToDeleteUser',
  sendOtp: '/user/sendOtpToDeleteUser',
  login: '/user/login',
  signup: '/user/createUser',
  addUser: '/user/addUser',
  getAllUsers: '/user/getAllUsers',
  getUserById: '/user/getUserById/:id',
  deleteUserById: '/user/deleteUserById',
  updateUserById: '/user/updateUserById',
  searchUser: '/user/search/',
  userNameValidation: '/user/userNameValidation/',

  getAllReviews: '/review/getAllReviews',
  updateReview: '/review/updateReview',
  deleteReview: '/review/deleteReview',
  searchReviews: '/review/search/',
  
  saveAirportData: '/airport/createAirport',
  getAllAirportData: '/airport/getAllAirportData',
  updateAirportDataFromCSV: '/airport/updateAirportDataFromCSV',
  updateIndividualAirportData: '/airport/updateIndividualAirportData',
  deleteAirportData: '/airport/deleteAirportDataById',
  searchAirport: '/airport/search/',
  getAllStates: '/airport/getStateNameList',

  getFboData: "/fbo/getAirportFBO/",
  updateFboData: "/fbo/updateIndividualFBOData",
  saveFboData: "/fbo/createFBOBySuperAdmin",
  deleteFbo: "/fbo/deleteFBODataById/",

  termsAndCondition: "/details/termsAndCondition",
  privacyPolicy: "/details/privacyPolicy",
  getTermsAndCondition: "/details/getTermsAndCondition",
  getPrivacyPolicy: "/details/getPrivacyPolicy",

  getAllClaims: "/user/getAllClaimRequest",
  approveClaim: "/user/approveClaimRequest",
  rejectClaim: "/user/rejectClaimRequest",
  searchClaim: "/user/searchClaim",

  getAllAddFBO: "/user/getAllAddFBORequest",
  approveAddFBO: "/user/approveAddFBORequest",
  rejectAddFBO: "/user/rejectAddFBORequest",
  // searchClaim: "/user/searchClaim"

  getEventsForAdmin: "/fboEvent/getEventsForAdmin",
  creatEventByAdmin: "/fboEvent/creatEventByAdmin",
  searchFBO: "/fbo/searchFBO",
  uploadImage: "/fbo/uploadImage",
  deleteEvent: "/fboEvent/deleteEvent",

  uploadAirportImage: "/airport/uploadImage",

  // share and update request
  getShareAnUpdate: "/shareAnUpdate/getShareAnUpdate",
  getAirportNameList: "/airport/getAirportNameList",
  updateShareAnUpdate: "/shareAnUpdate/uploadImageAndupdateShareAnUpdate",
  changeStatusShareAnUpdate: "/shareAnUpdate/changeStatusShareAnUpdate",
  approveUnlistedFboUpdate: "/shareAnUpdate/approveUnlistedFboUpdate",

  // manage share and update
  createEditOnsiteFood: "/restaurants/createEditRestaurant",
  getOnsiteFoodList: "/restaurants/getRestaurants",
  deleteOnsiteFood: "/restaurants/deleteRestaurant/",
  removeImage: "/restaurants/removeImage",

  getSocialMediaContent: "/socialMediaContent/getSocialMediaContent",
  createEditSocialMediaContent: "/socialMediaContent/createEditSocialMediaContent",
  deleteSocialMediaContent: "/socialMediaContent/deleteSocialMediaContent/"
  
}

module.exports = {host, url}